<template>
	<div>
		<layout-default-new>
			<section class="hero is-danger">
				<div class="hero-body">
					<p class="title">Invalid Link</p>
					<p class="subtitle">
						The link that you have tried to reach is invalid or you don't right
						to access it!
					</p>
				</div>
			</section>
		</layout-default-new>
	</div>
</template>

<script>
export default {
	name: 'InvalidLink',
}
</script>

<style scoped></style>
